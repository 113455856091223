<template>
  <div class="aboutSection">
    <div class="gradient"></div>
    <animated-component>
      <div class="about">
        <div class="header">
          <img src="https://apes-r-us.s3.amazonaws.com/ape-head.svg" />
          <h1>ABOUT</h1>
        </div>
        <div class="subtext">
          Swinging through the metaverse with 8,444 apes in 2022, Apes R Us
          features bold, fun and unique NFTs to match your personality and
          style. Our apes encompass everyday traits along with some of your
          favourite characters you may have loved over the years of growing up.
        </div>

        <div class="subtext">
          The artwork that we have created is purely hand-drawn illustrations by
          Katun, a popular graffiti artist based in Malaysia that has stretched
          his wings globally over the last couple of years. He has worked with
          renowned artists such as Chris Brown, Dua Lipa, Post Malone and has
          also worked with brands such as Vans, Marshall, Footlocker, KFC, Oppo
          and many more. Katun has taken his craft to a whole new level being
          the lone designer for the collection of meticulously designed NFTs for
          our first Apes R Us release.
        </div>

        <div class="subtext">
          We are a small dynamic team that exudes the key values of integrity
          within our art, loyalty within our community members and the longevity
          of our collection— we are here to stay.
        </div>

        <div class="subtext">
          Apes R Us has created brand based collaborations by being the first
          NFT project to bring WWF-Malaysia into the metaverse and we will be
          revealing more soon. Joining our community means more than just art,
          it also provides community-based exclusive access to many portals
          throughout our roadmap that you will be able to unlock as we move
          forward and we’re excited to see each and every one of you join our
          journey.
        </div>

        <div class="subtext">
          The Apes R Us NFT will be minted on our website for Ξ0.0888, and will
          be tradable on secondary markets like OpenSea. 444 NFT’s will be
          retained for marketing, promotion, team and other use cases.
        </div>
      </div>
    </animated-component>

    <div class="slider">
      <ImageSlider />
    </div>
    <div class="gradientBottom"></div>
  </div>
</template>
<script>
// Imports.
import ImageSlider from './ImageSlider.vue';

export default {
  components: { ImageSlider },

  data() {
    return {};
  }
};
</script>
<style scoped lang="scss">
.aboutSection {
  background-color: #0c0f15;
  color: #fff;
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  width: 100%;
  min-height: 810px;
  position: relative;

  .gradient {
    background: linear-gradient(#0c0f15 10%, rgba(0, 0, 0, 0));
    position: absolute;
    width: 100%;
    height: 120px;
    top: 0;
  }

  .gradientBottom {
    background: linear-gradient(rgba(0, 0, 0, 0) 10%, #0c0f15);
    position: absolute;
    width: 100%;
    height: 120px;
    bottom: 0;
  }

  .about {
    padding: 100px 40px;

    font-size: 28px;
    margin-left: auto;
    margin-right: auto;

    .header {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 15px;

      img {
        height: 35px;
      }

      h1 {
        font-size: 25px;
      }
    }
    .subtext {
      font-size: 16px;
      margin-bottom: 20px;
    }
  }

  .images {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    min-width: 400px;
    gap: 10px;
    position: relative;

    .background {
      transform-style: preserve-3d;
      overflow: hidden;
      will-change: transform;
      position: absolute;
      height: 100vh;
      animation: slideshow 40s linear infinite;

      .image {
        width: 100vw;
        height: 100%;
        overflow: hidden;
        @keyframes slideshow {
          0% {
            top: 0;
          }
          100% {
            top: -200%;
          }
        }
      }
    }

    .imageColumn {
      display: flex;
      flex-direction: column;
      gap: 10px;
      transform-style: preserve-3d;
      overflow: hidden;
      will-change: transform;
      position: absolute;
      height: 100vh;
      animation: slideshow 40s linear infinite;

      img {
        width: 100vw;
        height: 200px;
        overflow: hidden;
        @keyframes slideshow {
          0% {
            top: 0;
          }
          100% {
            top: -100%;
          }
        }
        width: 200px;
        height: 200px;
        object-fit: contain;
      }
    }

    .imageColumnRight {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 100px;

      img {
        width: 200px;
        height: 200px;
        object-fit: contain;
      }
    }
  }
}

@media (max-width: 800px) {
  .aboutSection {
    grid-template-columns: 1fr;

    .slider {
      display: flex;
      justify-content: center;
    }

    .about {
      padding-bottom: 50px;
      text-align: center;

      .header {
        justify-content: center;
      }
    }
  }
}

@media (max-width: 800px) {
  .aboutSection .swiper {
    margin-left: -40vw;
  }
}

@media screen and (max-width: 479px) {
  .swiper-slide {
    max-width: 75%;
  }

  .aboutSection .swiper {
    margin-left: -52vw;
  }

  .aboutSection .swiper .picture img {
    width: auto;
    max-width: 70vw;
  }
}
</style>
