'use strict';

// Import our module information.
import actions from './actions';
import mutations from './mutations';

// Prepare state.
const state = {
  // startDate: new Date(1626897600 * 1000),
  shopAddress: '0x384b6066CD1e0aeA770790b3162a754B26ac5f15',
  shop: null,
  bucks: [],
  ownsBuck: false,
  merkleUserIndex: '',
  merkleClaimedStatus: true,
  merkleProofs: [],
  allowances: [],
  presaleTimes: [],
  startDate: new Date(Date.UTC(2022, 0, 28, 21, 0))
};

// Return the module prepared for use.
export default {
  namespaced: true,
  state,
  actions,
  mutations
};
