<template>
  <div class="faqSection">
    <div class="imageContainer">
      <img src="https://apes-r-us.s3.amazonaws.com/rotating-head.gif" />
    </div>
    <div class="container">
      <animated-component>
        <div class="heading">
          <img src="https://apes-r-us.s3.amazonaws.com/ape-head.svg" />
          <div>FAQ</div>
        </div>
      </animated-component>
      <div class="dropdownWrapper" v-for="item in faq" :key="item.title">
        <animated-component animationType="right">
          <DropdownItem
            class="faq"
            :title="item.title"
            :description="item.description"
          />
        </animated-component>
      </div>
    </div>
  </div>
</template>
<script>
import DropdownItem from './DropdownItem.vue';
export default {
  components: {
    DropdownItem
  },

  setup() {
    const faq = [
      {
        title: 'What is mint price?',
        description: ['Ξ0.0888 per NFT + gas ']
      },
      {
        title: 'Whitelist mint Details?',
        description: [
          "Whitelist sale will start on January 25th, the time will be revealed soon, ensure to watch our social media outlets so you don't miss it.",
          'The whitelist sale will be open for 3 days! This is to avoid a gas war and ensure everyone has ample time to be able to purchase, the whitelist is not oversubscribed, meaning if you are on the whitelist and purchase between this window you will be able to mint.',
          'You can mint a maximum of 2 Apes R Us NFT’s from your whitelisted wallet address during this period!'
        ]
      },
      {
        title:
          'Can I mint during the whitelist mint period if I was not whitelisted?',
        description: [
          'No, you will be able to participate when the public mint opens which will start at the close of the whitelist mint period.'
        ]
      },
      {
        title: 'Wen Public mint start?',
        description: [
          'Public mint will start on January 28th, it will automatically begin once the 3 day WL minting period has ended and yes, we have ensured that there will be a number of NFT’s available for public mint so be on time! Stay tuned to our social outlets for more details!'
        ]
      },
      {
        title:
          'Is there a maximum mint limit per transaction during public mint?',
        description: [
          'Yes, you can mint a maximum of 10 Apes R Us NFT’s per transaction during public mint!'
        ]
      },
      {
        title: 'How will minting work?',
        description: [
          'We will mint an “unrevealed” Apes R Us NFT. On the reveal day we will be setting the real metadata live and users will be able to “refresh metadata” on https://opensea.io/ to see their revealed Apes R Us NFT!'
        ]
      },

      {
        title: 'Wen reveal?',
        description: [
          'Reveal will take place within 48 Hours after sell out! As mentioned above, on the reveal day we will be setting the real metadata live and users will be able to “refresh metadata” on https://opensea.io/ to see their revealed Apes R Us NFT!'
        ]
      },

      {
        title:
          'Can I participate in the public mint if I was on the whitelist?',
        description: ['Yes, no wallets are excluded from the public mint.']
      },
      {
        title: 'Can I mint using a hardware wallet?',
        description: ['Yes you can.']
      },

      {
        title: 'What are the Licensing rights / Intellectual property?',
        description: [
          'Apes R Us holders are given commercial rights to their specific Apes R Us NFT. You can sell its likeness, make derivatives of it or you can give others permission to use it. It’s yours. We’ve given licensing rights over for the Apes R Us NFT’s, but not our logo, names (Apes R Us), site images, or other marketing material. If it isn’t your Apes R Us NFT, it isn’t your Intellectual Property. Most of these logos, names, and images related to Apes R Us have either trademarked pending status, or are the intellectual property of the artist(s).',
          'That said, just because our logo may appear somewhere on your Apes R Us NFT, that does not give you the right to use that logo other than on the Apes R Us NFT itself or on a derivative of that Apes R Us NFT. You can’t take the logo and use it to brand an item for sale or use it for marketing of a product. (We don’t mind personal use or fan art usage of our logos, just so long as you don’t try and sell them.) The bottom line here is to protect the club, it is important that someone can’t pretend to be Apes R Us and that they can’t pretend to be collaborating with us.',
          'Regarding the term “Apes R Us”. We allow usage of the term Apes R Us only when used in conjunction with your Apes R Us #. For instance, you can’t use Apes R Us alone on merch or NFT, but you can use Apes R Us#2383, Apes R Us#1567 etc.',
          'Think of it like this: You own your Apes R Us NFT, not Apes R Us, so accurately representing yourself / brand / product, while not infringing on our brand, is important.'
        ]
      },
      {
        title:
          'If I own an NFT from Katun’s “Apes Stand Together” NFT collection am I eligible for a free claim?',
        description: [
          'Yes, you only pay for gas! The free claim will start on January 25th and remain open for the duration of 3 days, at the start of public mint unclaimed NFT’s will be no longer claimable and will be allocated to the public mint.',
          'If you own a Apes Stand Strong limited edition NFT you are eligible to claim 2 free Apes R Us NFT’s + gas.',
          'If you own either a Mystical Fruit or Garden of Bloom NFT from the collection you are eligible to claim 1 free Apes R Us NFT + gas.',
          'It is important to note that It is 1 claim per wallet address! You will not have multiple claims per a wallet even if you hold multiple NFT’s from the “Apes Stand Together” collection in 1 wallet! The claim access will be done via a snapshot system, if you held eg 2 “Apes Stand Strong” NFT’s in 1 wallet you would only be eligible for 1 claim (as if you were only holding 1 in your wallet) and therefore if you hold multiple, we suggest that you separate them to different wallets before the snapshot is taken which will happen prior to WL mint going live.'
        ]
      },
      {
        title: 'How do I claim my free Apes R Us NFT if I am eligible?',
        description: [
          'For free claims when you enter the website you will notice 2 pools (Buttons), both pools will be clearly identified with a brief description providing information on which pool is for the free claim snapshot (you only need to pay for gas) and the second pool which will be for those whitelisted.'
        ]
      },

      {
        title: 'Information about the 1 of 1’s and the WWF Themed NFT’s?',
        description: [
          'We always want to be fully transparent! The 1 of 1’s and the WWF Themed NFT’s are a part of the 444 reserved mints. ',
          'The 1 of 1’s will be held for various purposes, these range from marketing, community engagement, team members, auctions to future partnerships / collaborations. In the 1 of 1’s there are 4 honorary 1 of 1 New Era NFT’s, these are to showcase / celebrate our partnership with them and they will be sent to New Era themselves.',
          'We are sure by now you know that we have a partnership with WWF-Malaysia, there are 100 WWF Themed NFT’s that will be minted and sold in an auction where all proceeds raised will be sent to WWF-Malaysia!'
        ]
      }
    ];

    return {
      faq
    };
  }
};
</script>
<style scoped lang="scss">
.faqSection {
  background-color: black;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 150px 0px;
  margin: auto;

  .imageContainer {
    max-width: 600px;
    width: 100%;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .container {
    width: 100%;
    padding: 40px;
    max-width: 1600px;

    .heading {
      font-size: 32px;
      font-weight: 700;
      text-transform: uppercase;

      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;

      img {
        height: 35px;
        width: 35px;
        object-fit: contain;
        margin-right: 10px;
      }
    }
  }
}

@media (max-width: 800px) {
  .faqSection {
    grid-template-columns: 1fr;

    .container {
      .heading {
        justify-content: center;
      }
    }
  }
}

@media (max-width: 600px) {
  .faqSection .container .dropdownWrapper .faq {
    margin: 20px 0;
  }
}
</style>
