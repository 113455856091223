<template>
  <div class="teamSection">
    <animated-component>
      <div class="header">
        <img src="https://apes-r-us.s3.amazonaws.com/ape-head.svg" />
        <div class="title">TEAM</div>
      </div>
    </animated-component>

    <animated-component>
      <div class="teamContainer">
        <div class="memberWrapper">
          <div class="imageWrapper">
            <img src="../../../images/david.jpg" />
          </div>

          <p class="name">David.K</p>
          <p class="role">Co-Founder</p>
          <div class="socialsWrapper">
            <TwitterIcon
              class="icon"
              @click="openUrl('https://twitter.com/ApesRus_io')"
            />
            <InstagramIcon
              class="icon"
              @click="openUrl('https://www.instagram.com/4stages')"
            />
            <NewWindowIcon
              class="icon"
              @click="openUrl('https://www.4-stages.com/')"
            />
          </div>
        </div>

        <div class="memberWrapper">
          <div class="imageWrapper">
            <img src="../../../images/katun.jpg" />
          </div>

          <p class="name">Katun</p>
          <p class="role">Co-Founder/Artist</p>
          <div class="socialsWrapper">
            <TwitterIcon
              class="icon"
              @click="openUrl('https://twitter.com/KatunVisual')"
            />
            <InstagramIcon
              class="icon"
              @click="openUrl('https://www.instagram.com/katun_')"
            />
          </div>
        </div>
      </div>
    </animated-component>
  </div>
</template>
<script>
import TwitterIcon from '/src/components/icons/TwitterIcon';
import InstagramIcon from '/src/components/icons/InstagramIcon';
import NewWindowIcon from '/src/components/icons/NewWindowIcon';
export default {
  components: {
    TwitterIcon,
    InstagramIcon,
    NewWindowIcon
  },

  setup() {
    const openUrl = url => {
      window.open(url, '_blank');
    };

    return {
      openUrl
    };
  }
};
</script>
<style scoped lang="scss">
.teamSection {
  background-image: url('https://apes-r-us.s3.amazonaws.com/apes-bg.png');
  background-position: center;
  background-size: contain;
  padding: 100px 40px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 40px;
    img {
      height: 35px;
      width: 35px;
      margin-right: 10px;
    }

    .title {
      font-weight: bold;
      font-size: 30px;
    }
  }

  .teamContainer {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    .memberWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      .socialsWrapper {
        margin-top: 20px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;

        .icon {
          height: 16px;
          width: 16px;
          transition: all 200ms ease;
          cursor: pointer;

          &:hover {
            opacity: 0.7;
          }

          &:active {
            opacity: 1;
          }
        }
      }

      .imageWrapper {
        max-width: 250px;
        img {
          width: 100%;
          object-fit: contain;
        }
        margin-bottom: 10px;
      }

      p {
        margin: 0;
      }

      .name {
        font-weight: 600;
      }

      .role {
        font-size: 14px;
        opacity: 0.5;
      }

      .description {
        font-size: 12px;
        opacity: 0.8;
        max-width: 80%;
      }
    }
  }
}
</style>
