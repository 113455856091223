<template>
  <div id="container">
    <!-- Each image is 350px by 233px -->
    <div class="photobanner">
      <div class="imgContainer">
        <img
          src="https://apes-r-us.s3.amazonaws.com/sample-apes/1.png"
          alt=""
        />
      </div>
      <div class="imgContainer">
        <img
          src="https://apes-r-us.s3.amazonaws.com/sample-apes/2.png"
          alt=""
        />
      </div>
      <div class="imgContainer">
        <img
          src="https://apes-r-us.s3.amazonaws.com/sample-apes/3.png"
          alt=""
        />
      </div>
      <div class="imgContainer">
        <img
          src="https://apes-r-us.s3.amazonaws.com/sample-apes/4.png"
          alt=""
        />
      </div>
      <div class="imgContainer">
        <img
          src="https://apes-r-us.s3.amazonaws.com/sample-apes/5.png"
          alt=""
        />
      </div>
      <div class="imgContainer">
        <img
          src="https://apes-r-us.s3.amazonaws.com/sample-apes/6.png"
          alt=""
        />
      </div>
      <div class="imgContainer">
        <img
          src="https://apes-r-us.s3.amazonaws.com/sample-apes/7.png"
          alt=""
        />
      </div>
      <div class="imgContainer">
        <img
          src="https://apes-r-us.s3.amazonaws.com/sample-apes/8.png"
          alt=""
        />
      </div>
      <div class="imgContainer">
        <img
          src="https://apes-r-us.s3.amazonaws.com/sample-apes/9.png"
          alt=""
        />
      </div>
      <div class="imgContainer">
        <img
          src="https://apes-r-us.s3.amazonaws.com/sample-apes/10.png"
          alt=""
        />
      </div>
    </div>

    <div class="photobanner offset">
      <div class="imgContainer offset">
        <img
          src="https://apes-r-us.s3.amazonaws.com/sample-apes/11.png"
          alt=""
        />
      </div>
      <div class="imgContainer">
        <img
          src="https://apes-r-us.s3.amazonaws.com/sample-apes/12.png"
          alt=""
        />
      </div>
      <div class="imgContainer">
        <img
          src="https://apes-r-us.s3.amazonaws.com/sample-apes/13.png"
          alt=""
        />
      </div>
      <div class="imgContainer">
        <img
          src="https://apes-r-us.s3.amazonaws.com/sample-apes/14.png"
          alt=""
        />
      </div>
      <div class="imgContainer">
        <img
          src="https://apes-r-us.s3.amazonaws.com/sample-apes/15.png"
          alt=""
        />
      </div>
      <div class="imgContainer">
        <img
          src="https://apes-r-us.s3.amazonaws.com/sample-apes/16.png"
          alt=""
        />
      </div>
      <div class="imgContainer">
        <img
          src="https://apes-r-us.s3.amazonaws.com/sample-apes/17.png"
          alt=""
        />
      </div>
      <div class="imgContainer">
        <img
          src="https://apes-r-us.s3.amazonaws.com/sample-apes/18.png"
          alt=""
        />
      </div>
      <div class="imgContainer">
        <img
          src="https://apes-r-us.s3.amazonaws.com/sample-apes/19.png"
          alt=""
        />
      </div>
      <div class="imgContainer">
        <img
          src="https://apes-r-us.s3.amazonaws.com/sample-apes/7.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
}

#container {
  height: 100vh;
  overflow: hidden;
  display: flex;
  gap: 5px;
}

/*photobanner*/

.photobanner {
  width: 250px;
  height: 2500px;
  animation: bannermove 30s linear infinite;
  display: flex;
  flex-direction: column;
  gap: 15px;

  .imgContainer {
    height: 250px;
    width: 250px;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}

.offset {
  margin-top: -125px;
}

@keyframes bannermove {
  0% {
    margin-top: 0px;
  }
  100% {
    margin-top: -1250px;
  }
}

@media (max-width: 800px) {
  #container {
    height: 500px;
    overflow: hidden;
    display: flex;
    gap: 10px;
  }
  .photobanner {
    width: 100%;
    height: 100%;
    gap: 5px;
    .imgContainer {
      width: 100%;
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }
}
</style>
