<template>
  <div class="imagesSection">
    <animated-component class="apeImageContainer" animationType="left">
      <img src="https://apes-r-us.s3.amazonaws.com/comics/1.png" />
    </animated-component>

    <animated-component animationType="right" class="topRowWrapper">
      <img src="https://apes-r-us.s3.amazonaws.com/comics/2.png" />
    </animated-component>

    <div class="middleRowWrapper">
      <animated-component animationType="left" class="left">
        <img
          class="top"
          src="https://apes-r-us.s3.amazonaws.com/comics/3.png"
        />
        <img src="https://apes-r-us.s3.amazonaws.com/comics/4.png" />
      </animated-component>
      <animated-component animationType="right" class="right">
        <img src="https://apes-r-us.s3.amazonaws.com/comics/5.png" />
      </animated-component>
    </div>

    <animated-component class="bottomRowWrapper">
      <img src="https://apes-r-us.s3.amazonaws.com/comics/6.png" />
      <p>
        Partnership with New Era for exclusive merch accessible to Apes R Us
        members only.
      </p>
    </animated-component>

    <!-- <div class="top">
      <animated-component animationType="left" class="one">
        <img
          class="one"
          src="https://apes-r-us.s3.amazonaws.com/comics/1.png"
        />
      </animated-component>

      <animated-component animationType="right" class="two">
        <img
          class="two"
          src="https://apes-r-us.s3.amazonaws.com/comics/2.png"
        />
        <img
          class="three"
          src="https://apes-r-us.s3.amazonaws.com/comics/3.png"
        />
      </animated-component>
    </div>

    <div class="middle">
      <animated-component>
        <div class="three">
          <img
            class="three"
            src="https://apes-r-us.s3.amazonaws.com/comics/3.png"
          />
        </div>
      </animated-component>
    </div>

    <div class="middle2">
      <animated-component>
        <div class="four">
          <img
            class="four"
            src="https://apes-r-us.s3.amazonaws.com/comics/4.png"
          />
        </div>
      </animated-component>
    </div>
    <animated-component>
      <div class="bottom">
        <div class="five">
          <img
            class="five"
            src="https://apes-r-us.s3.amazonaws.com/comics/5.png"
          />
        </div>

        <div class="six">
          <img
            class="six"
            src="https://apes-r-us.s3.amazonaws.com/comics/6.png"
          />
        </div>
      </div>
    </animated-component> -->
  </div>
</template>
<script></script>
<style scoped lang="scss">
.imagesSection {
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 100%;
  padding-top: 30px;

  .apeImageContainer {
    max-width: 50%;
    min-width: 400px;
    position: absolute;
    z-index: 1;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .topRowWrapper {
    max-width: 700px;
    margin-top: 200px;
    margin-left: auto;
    padding-left: 20px;
    z-index: 3;
    margin-bottom: 50px;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .middleRowWrapper {
    display: flex;
    width: 100%;
    gap: 20px;
    justify-content: center;
    align-items: center;
    z-index: 2;
    padding: 0px 20px;

    .left {
      display: flex;
      flex-direction: column;
      gap: 20px;
      max-width: 600px;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }

      .top {
        margin-right: 50px;
        margin-bottom: 20px;
      }
    }

    .right {
      max-width: 375px;
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }

  .bottomRowWrapper {
    max-width: 100%;
    padding: 0px 20px;
    width: 100%;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }

    p {
      text-align: center;
      font-style: italic;
      opacity: 0.7;
      padding: 20px;
    }
  }

  .header {
    display: flex;
    align-items: center;
    gap: 10px;

    img {
      height: 40px;
    }

    h1 {
      font-size: 25px;
    }
  }
  .top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin-top: -40px;
    max-width: 1200px;

    .one {
      max-width: 600px;

      img {
        width: 100%;
        object-fit: contain;
      }
    }

    .two {
      // margin-top: -50px;
      max-width: 650px;

      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }

  .middle {
    margin-top: -10%;
    margin-left: 200px;
    .three {
      max-width: 700px;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }

  .middle2 {
    margin-left: 50%;
    margin-right: 10%;
    .four {
      max-width: 500px;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }

  .bottom {
    margin-top: -20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .five {
      max-width: 300px;
      img {
        width: 100%;
        object-fit: contain;
      }
    }

    .six {
      max-width: 900px;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }
}

@media (max-width: 800px) {
  .imagesSection {
    .middleRowWrapper {
      margin-top: 20px;
      flex-direction: column;
    }
  }
}
</style>
